<template>
    <div class="my-5 pb-5" style="padding: 80px 0px 0px;">
        <v-container>

            <div class="text-center greys lighten-3 py-5">
                <v-avatar size="150">
                    <img :src="$store.state.ngayi.image" class="img-fluid" />
                </v-avatar>    
                <h4 class="py-3">Welcome, <b>{{ $store.state.ngayi.fullname }}</b>!</h4>
            </div>

            <hr />

            <section class="text-center" style="margin: 20px 0px 0px;">
                <div class="container">
                    <h2 class="fw-bold"><strong><span style="color: rgb(0, 0, 0);">Dashboard</span></strong></h2>
                    <p style="color: var(--bs-black);">A non-profit orgonization dedicated to assisting non-English
                        speakers by providing scholarship funds, enabling them to thrive on their academic journeys.</p>
                    <div class="my-5 my-5">
                        <!-- <v-text-field outlined dense label="Search" /> -->
                    </div>
                </div>
            </section>

        </v-container>
        <section style="padding: 0px;">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <v-row>
                            <v-col cols="12" class="col-md-3 p-2" v-for="(x,k) in products" :key="k">
                                <v-card class="elevation-0 bg-tranparent grey lighten-4" height="100%">
                                    <DashboardList :product="x" :color="''" :bg="''" />
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                languages: [],
                page: 0,
                products: [{
                    name: "Team Member",
                    src: "users",
                    link: "teams"
                }, {
                    name: "Schools",
                    src: "school",
                    link: "schools"
                }, {
                    name: "Students",
                    src: "graduation-cap",
                    link: "students"
                }, {
                    name: "Quiz",
                    src: "question",
                    link: "quiz"
                }, ]
            }
        },
        created() {
            this.languages.push(this.$store.state.Engish_filter); 
        },
        components: {
            DashboardList: () => import("./DashboardList.vue")
        }

    }
</script>

<style>

</style>